import { gql } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import { CollectionId, HexId, ProjectRole } from "@hex/common";
import { groupBy } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import {
  HexButton,
  HexCallout,
  HexDialog,
  HexNonIdealState,
  HexSpinner,
  HexTooltip,
} from "../../../../hex-components";
import { WarningIcon } from "../../../icons/CustomIcons";

import { PotentialCollection } from "./AddCollectionsBar";
import { AddCollectionsToProjectSection } from "./AddCollectionsToProjectSection";
import {
  useAddCollectionsToHexMutation,
  useHexForAddToCollectionsDialogQuery,
} from "./AddProjectToCollectionsDialog.generated";

const Title = styled.div<{ $small: boolean }>`
  align-items: flex-start;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ $small, theme }) =>
    $small ? theme.fontSize.EXTRA_LARGE : theme.fontSize.HEADING_ONE};
`;

const StyledDialog = styled(HexDialog)`
  && {
    width: 520px;

    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  padding: 20px;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
`;

const CollectionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Subheading = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

export const AddCollectionDialogHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  min-width: 0;
`;

export const AddCollectionDialogCollectionName = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  min-width: 0;
  padding-right: 5px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;

  border-top: 1px solid ${({ theme }) => theme.borderColor.MUTED};
`;

const RightActions = styled.div`
  display: flex;
  gap: 8px;
`;

export const CollectionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 30px;

  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const EmojiWrapper = styled.div`
  font-size: 15px;
`;

gql`
  fragment AddToCollectionLinkFragment on CollectionHexLink {
    id
    projectRole
    collection {
      id
      name
      emoji
      canManage
      collectionGrants {
        id
        ...CollectionGrantFragment
      }
    }
  }

  query HexForAddToCollectionsDialog($hexId: HexId!) {
    hexById(hexId: $hexId) {
      id
      collectionHexLinks: safeCollectionHexLinks {
        ...AddToCollectionLinkFragment
      }
    }
  }

  mutation AddCollectionsToHex(
    $hexId: HexId!
    $appUserCollectionIds: [CollectionId!]!
    $viewerCollectionIds: [CollectionId!]!
    $editorCollectionIds: [CollectionId!]!
    $ownerCollectionIds: [CollectionId!]!
    $noAddedGrantCollectionIds: [CollectionId!]!
  ) {
    addCollectionsToHex(
      hexId: $hexId
      appUserCollectionIds: $appUserCollectionIds
      viewerCollectionIds: $viewerCollectionIds
      editorCollectionIds: $editorCollectionIds
      ownerCollectionIds: $ownerCollectionIds
      noAddedGrantCollectionIds: $noAddedGrantCollectionIds
    ) {
      id
      maxGrantableRole
      collectionHexLinks: safeCollectionHexLinks {
        ...AddToCollectionLinkFragment
      }
    }
  }
`;

export interface CollectionWithRole extends PotentialCollection {
  role: ProjectRole | null;
}

interface AddProjectToCollectionsDialogProps {
  hexId: HexId;
  isComponent: boolean;
  canShare: boolean;
  maxGrantableRole: ProjectRole | null;
  title: string;
  closeDialog: () => void;
  isOpen?: boolean;
}

export const AddProjectToCollectionsDialog: React.ComponentType<AddProjectToCollectionsDialogProps> =
  React.memo(function AddProjectToCollectionsDialog({
    canShare,
    closeDialog,
    hexId,
    isComponent,
    isOpen = true,
    maxGrantableRole,
    title,
  }) {
    const [saveError, setError] = useState<string>();

    const {
      data,
      error: queryError,
      loading: queryLoading,
    } = useHexForAddToCollectionsDialogQuery({ variables: { hexId } });

    const alreadySavedCollectionIds: Set<CollectionId> = useMemo(
      () =>
        new Set(data?.hexById.collectionHexLinks.map((l) => l.collection.id)),
      [data?.hexById.collectionHexLinks],
    );
    const [isPendingSearch, setIsPendingSearch] = useState<boolean>(false);

    // the list of collections that have been added through the 'add' button
    const [collectionsForUpdate, setCollectionsForUpdate] = useState<
      CollectionWithRole[]
    >([]);

    const clearState = useCallback(() => {
      setError(undefined);
      setCollectionsForUpdate([]);
    }, []);

    const closeDialogCallback = useCallback(() => {
      clearState();
      closeDialog();
    }, [clearState, closeDialog]);

    const [addCollectionsMutation, { loading: addLoading }] =
      useAddCollectionsToHexMutation();

    const addCollectionsToProject = useCallback(async () => {
      const groupedLinks = groupBy(collectionsForUpdate, "role");
      try {
        await addCollectionsMutation({
          variables: {
            hexId,
            appUserCollectionIds:
              groupedLinks[ProjectRole.APP_USER]?.map((p) => p.id) ?? [],
            viewerCollectionIds:
              groupedLinks[ProjectRole.VIEWER]?.map((p) => p.id) ?? [],
            editorCollectionIds:
              groupedLinks[ProjectRole.EDITOR]?.map((p) => p.id) ?? [],
            ownerCollectionIds:
              groupedLinks[ProjectRole.OWNER]?.map((p) => p.id) ?? [],
            // because we groupBy a nullable field (role), all null groupedLinks have no added grants.
            noAddedGrantCollectionIds:
              groupedLinks["null"]?.map((p) => p.id) ?? [],
          },
        });
        closeDialog();
      } catch (e) {
        console.error(e);
        setError("Error adding projects to collection");
      }
    }, [addCollectionsMutation, closeDialog, hexId, collectionsForUpdate]);

    return (
      <StyledDialog
        isCloseButtonShown={true}
        isOpen={isOpen}
        title="Add project to collections"
        usePortal={true}
        onClose={closeDialogCallback}
      >
        <Container>
          <Body>
            <TitleSection>
              <Subheading>Adding:</Subheading>
              <Title $small={true}>{title}</Title>
            </TitleSection>
            <CollectionSection>
              <Subheading>To the following collection(s):</Subheading>
              {queryLoading && <HexSpinner />}
              {queryError && (
                <HexNonIdealState
                  icon={<WarningIcon />}
                  title="Error loading"
                />
              )}
              {!queryLoading && !queryError && (
                <AddCollectionsToProjectSection
                  canShare={canShare}
                  collections={collectionsForUpdate}
                  disabledCollectionIds={alreadySavedCollectionIds}
                  isComponent={isComponent}
                  maxGrantableRole={maxGrantableRole}
                  setActiveTagsInSearch={setIsPendingSearch}
                  setCollections={setCollectionsForUpdate}
                />
              )}
            </CollectionSection>
            {saveError && (
              <HexCallout
                $size="small"
                icon={<WarningIcon />}
                intent={Intent.DANGER}
              >{`${saveError}. Try again, or contact support if the issue persists.`}</HexCallout>
            )}
          </Body>
        </Container>
        <Footer>
          <RightActions>
            <HexButton text="Cancel" onClick={closeDialog} />
            <HexTooltip
              content="There are pending Collections in the search bar. Please add or remove."
              disabled={!isPendingSearch}
              interactionKind="hover"
              position="top"
            >
              <HexButton
                disabled={isPendingSearch}
                intent={Intent.PRIMARY}
                loading={addLoading}
                text="Add project"
                onClick={addCollectionsToProject}
              />
            </HexTooltip>
          </RightActions>
        </Footer>
      </StyledDialog>
    );
  });
