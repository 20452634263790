import { gql } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import {
  CollectionId,
  CollectionOwnershipLevel,
  CollectionRole,
  OrgRole,
  isOrgRoleSuperset,
} from "@hex/common";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  HexButton,
  HexCallout,
  HexDialog,
  HexTooltip,
} from "../../../../hex-components";
import { useCurrentUser } from "../../../../hooks/me/useCurrentUser.js";
import { useGlobalHotKey } from "../../../../hooks/useGlobalHotKey.js";
import { ORG_ID } from "../../../../orgs";
import { Routes } from "../../../../route/routes";
import { useDialog } from "../../../../util/dialogs";
import { HotKeys } from "../../../../util/hotkeys";
import { WarningIcon } from "../../../icons/CustomIcons.js";
import { GetCollectionsForHome2Document } from "../collections-list/CollectionsListTab2.generated.js";
import { useUpdateCollectionGrantsData } from "../hooks/useUpdateCollectionGrants.js";
import { CollectionDescription } from "../shared/CollectionDescription.js";
import {
  CollectionName,
  DEFAULT_COLLECTION_TITLE,
  OnSaveCollectionMetadataArgs,
} from "../shared/CollectionName.js";

import {
  CollectionMembership,
  MemberGroup,
  MemberUser,
} from "./CollectionMembership.js";
import { useCreateCollectionMutation } from "./NewCollectionDialog.generated.js";

gql`
  mutation CreateCollection(
    $orgId: OrgId!
    $name: String!
    $description: String
    $emoji: String
    $memberUserIds: [UserId!]!
    $memberGroupIds: [GroupId!]!
    $memberIntrinsicGroupIds: [IntrinsicGroupId!]!
    $managerIntrinsicGroupIds: [IntrinsicGroupId!]!
    $managerUserIds: [UserId!]!
    $managerGroupIds: [GroupId!]!
  ) {
    createCollection(
      orgId: $orgId
      name: $name
      description: $description
      emoji: $emoji
      memberUserIds: $memberUserIds
      memberGroupIds: $memberGroupIds
      memberIntrinsicGroupIds: $memberIntrinsicGroupIds
      managerIntrinsicGroupIds: $managerIntrinsicGroupIds
      managerUserIds: $managerUserIds
      managerGroupIds: $managerGroupIds
    ) {
      id
      name
    }
  }
`;

export const NewCollectionDialog = React.memo(function NewCollectionDialog() {
  const history = useHistory();

  const {
    closeDialog: closeDialog_,
    isOpen,
    openDialog,
  } = useDialog("new-collection");

  const [createCollection] = useCreateCollectionMutation();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isPendingSearch, setIsPendingSearch] = useState<boolean>(false);

  const [currentName, setCurrentName] = useState<string>(
    DEFAULT_COLLECTION_TITLE,
  );
  const [creationError, setCreationError] = useState<string>();
  const [currentDescription, setCurrentDescription] = useState<string>("");
  const [currentEmoji, setCurrentEmoji] = useState<string | null>(null);

  const [selectedUsers, setSelectedUsers] = useState<MemberUser[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<MemberGroup[]>([]);
  const [orgCollectionRole, setOrgCollectionRole] = useState<
    CollectionRole | undefined
  >();

  const clearState = useCallback(() => {
    setIsSaving(false);
    setCreationError(undefined);
    setCurrentName(DEFAULT_COLLECTION_TITLE);
    setCurrentDescription("");
    setCurrentEmoji(null);
    setSelectedUsers([]);
    setSelectedGroups([]);
    setOrgCollectionRole(undefined);
  }, []);

  const closeDialog = useCallback(() => {
    clearState();
    closeDialog_();
  }, [clearState, closeDialog_]);

  const toggleDialog = useCallback(() => {
    if (isOpen) {
      closeDialog();
    } else {
      openDialog();
    }
  }, [closeDialog, isOpen, openDialog]);

  const saveNameAndEmoji = useCallback(
    ({ newEmoji, newTitle }: OnSaveCollectionMetadataArgs): void => {
      if (newTitle !== undefined) {
        setCurrentName(newTitle ?? DEFAULT_COLLECTION_TITLE);
      }

      if (newEmoji !== undefined) {
        setCurrentEmoji(newEmoji);
      }
    },
    [],
  );

  const {
    managerGroupIds,
    managerIntrinsicGroupIds,
    managerUserIds,
    memberGroupIds,
    memberIntrinsicGroupIds,
    memberUserIds,
  } = useUpdateCollectionGrantsData(
    selectedUsers,
    selectedGroups,
    orgCollectionRole,
  );

  const saveCollectionCallback = useCallback(async (): Promise<{
    collectionId: CollectionId;
    collectionName: string;
  }> => {
    const args = {
      name: currentName,
      description: currentDescription,
      emoji: currentEmoji,
      memberUserIds,
      memberGroupIds,
      managerUserIds,
      managerGroupIds,
      memberIntrinsicGroupIds,
      managerIntrinsicGroupIds,
    };

    const { data } = await createCollection({
      variables: {
        orgId: ORG_ID,
        ...args,
      },
      refetchQueries: [
        {
          query: GetCollectionsForHome2Document,
          variables: { ownershipLevel: CollectionOwnershipLevel.ALL },
        },
      ],
    });
    if (data?.createCollection == null) {
      throw new Error("Unable to save collection");
    }
    return {
      collectionId: data.createCollection.id,
      collectionName: data.createCollection.name,
    };
  }, [
    createCollection,
    currentDescription,
    currentEmoji,
    currentName,
    managerGroupIds,
    managerIntrinsicGroupIds,
    managerUserIds,
    memberGroupIds,
    memberIntrinsicGroupIds,
    memberUserIds,
  ]);

  const saveCollectionAndNavigate = useCallback(async () => {
    setIsSaving(true);
    try {
      const { collectionId, collectionName } = await saveCollectionCallback();
      clearState();
      Routes.push(history, Routes.COLLECTION, {
        collectionId,
        collectionName,
      });
    } catch (e) {
      console.error(e);
      setCreationError("Unable to save collection");
      setIsSaving(false);
    }
  }, [clearState, saveCollectionCallback, history]);

  // New collection hot key
  useGlobalHotKey(
    (evt) => {
      if (!isOpen) {
        evt.preventDefault();
        toggleDialog();
        return true;
      }
      return false;
    },
    [isOpen, toggleDialog],
    { keys: HotKeys.NEW_COLLECTION, name: "New collection" },
  );

  const currentUser = useCurrentUser();
  // if a user that cannot shareProjects attempts to access the new/edit collection dialog, redirect to previous page and do not share dialog.
  const canShareProjects = currentUser
    ? isOrgRoleSuperset(currentUser.orgRole, OrgRole.EDITOR)
    : false;

  return canShareProjects ? (
    <StyledDialog
      isCloseButtonShown={true}
      isOpen={isOpen}
      title="Create new collection"
      usePortal={true}
      onClose={toggleDialog}
    >
      <Container>
        <CollectionMetadata>
          <CollectionName
            emoji={currentEmoji}
            name={currentName}
            small={true}
            updateCollectionProps={{
              canEdit: true,
              onSave: saveNameAndEmoji,
            }}
          />
          <CollectionDescription
            description={currentDescription}
            updateCollectionProps={{
              canEdit: true,
              onSave: ({ newDescription }: OnSaveCollectionMetadataArgs) => {
                setCurrentDescription(newDescription ?? "");
              },
            }}
          />
        </CollectionMetadata>
        <CollectionMembership
          canEdit={true}
          css={`
            padding: 40px;
          `}
          orgCollectionRole={orgCollectionRole}
          persistedIsSharedCollection={false}
          selectedGroups={selectedGroups}
          selectedUsers={selectedUsers}
          setActiveTagsInSearch={setIsPendingSearch}
          setOrgCollectionRole={setOrgCollectionRole}
          setSelectedGroups={setSelectedGroups}
          setSelectedUsers={setSelectedUsers}
        />
      </Container>
      {creationError != null && (
        <HexCallout
          $size="small"
          css={`
            margin: 0px 20px 20px 20px;
            width: 480px;
          `}
          icon={<WarningIcon />}
          intent={Intent.DANGER}
        >{`${creationError}. Try again, or contact support if the issue persists.`}</HexCallout>
      )}
      <Footer>
        <RightActions>
          <HexButton text="Cancel" onClick={closeDialog} />
          <HexTooltip
            content="There are pending users or groups in the search bar. Please add or remove"
            disabled={!isPendingSearch}
            position="top"
          >
            <HexButton
              disabled={isPendingSearch}
              intent={Intent.SUCCESS}
              loading={isSaving}
              text="Create collection"
              onClick={saveCollectionAndNavigate}
            />
          </HexTooltip>
        </RightActions>
      </Footer>
    </StyledDialog>
  ) : null;
});

const StyledDialog = styled(HexDialog)`
  && {
    width: 520px;
    max-height: 88vh;
    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  padding: 20px;
  overflow: auto;
`;

const CollectionMetadata = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;

  border-top: 1px solid ${({ theme }) => theme.borderColor.MUTED};
`;

const RightActions = styled.div`
  display: flex;
  gap: 8px;
`;
