import { Classes, Intent } from "@blueprintjs/core";
import { CollectionId, ProjectRole } from "@hex/common";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { HexMenuDivider, HexMenuItem } from "../../../../hex-components";
import { useHexFlag } from "../../../../util/useHexFlags.js";
import { useToaster } from "../../../common/Toasts";
import { CollectionIcon } from "../../../icons/CustomIcons";
import { RoleDropdown } from "../../../share/RoleDropdown";

import { AddCollectionsBar, PotentialCollection } from "./AddCollectionsBar";
import { CollectionWithRole } from "./AddProjectToCollectionsDialog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const CollectionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow: auto;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

const CollectionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddCollectionDialogHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  min-width: 0;
`;

export const AddCollectionDialogCollectionName = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  min-width: 0;
  padding-right: 5px;
`;

const Permission = styled.div`
  flex-shrink: 0;
  align-content: flex-end;
`;

export const CollectionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 30px;

  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const EmojiWrapper = styled.div`
  font-size: 15px;
`;

interface AddCollectionsToProjectSectionProps {
  collections: CollectionWithRole[];
  setCollections: (collections: CollectionWithRole[]) => void;
  disabledCollectionIds: Set<CollectionId>;
  canShare: boolean;
  maxGrantableRole: ProjectRole | null;
  setActiveTagsInSearch?: (hasActiveTags: boolean) => void;
  isComponent: boolean;
}

export const AddCollectionsToProjectSection: React.ComponentType<AddCollectionsToProjectSectionProps> =
  React.memo(function NewProjectAddCollections({
    canShare,
    collections,
    disabledCollectionIds,
    isComponent,
    maxGrantableRole,
    setActiveTagsInSearch,
    setCollections,
  }) {
    const toaster = useToaster();
    const explorerRoleCanViewChange = useHexFlag(
      "explorer-role-can-view-change",
    );

    const excludedCollectionIds = useMemo(() => {
      return new Set(collections.map((c) => c.id));
    }, [collections]);

    const addCollectionsForUpdate = useCallback(
      ({
        collections: newCollections,
        role,
      }: {
        collections: PotentialCollection[];
        role: ProjectRole | null;
      }) => {
        const collectionsWithRoles: CollectionWithRole[] = newCollections.map(
          (p) => ({
            role,
            ...p,
          }),
        );
        setCollections([...collections, ...collectionsWithRoles]);
      },
      [collections, setCollections],
    );

    const updateProjectRole = useCallback(
      (collectionId: CollectionId, selectedRole: ProjectRole | null) => {
        const existingIndex = collections.findIndex(
          (p) => p.id === collectionId,
        );

        if (existingIndex === -1) {
          toaster.show({
            message:
              "Failed to update the collection's permissions for the project.",
            intent: Intent.DANGER,
          });
          return;
        }

        setCollections(
          collections
            .slice(0, existingIndex)
            .concat({
              ...collections[existingIndex],
              role: selectedRole,
            })
            .concat(collections.slice(existingIndex + 1)),
        );
      },
      [collections, setCollections, toaster],
    );

    const onRemove = useCallback(
      (collectionId: CollectionId) => {
        setCollections(collections.filter((col) => col.id !== collectionId));
      },
      [collections, setCollections],
    );

    return (
      <Container>
        <AddCollectionsBar
          addCollectionsCallback={addCollectionsForUpdate}
          canShare={canShare}
          disabledIdsInSearch={disabledCollectionIds}
          excludedIdsFromSearch={excludedCollectionIds}
          isComponent={isComponent}
          maxRole={maxGrantableRole ?? ProjectRole.EDITOR}
          setActiveTagsInSearch={setActiveTagsInSearch}
        />
        {collections.length > 0 && (
          <CollectionList>
            {collections.map((collection) => (
              <CollectionRow key={collection.id}>
                <AddCollectionDialogHeader>
                  <CollectionIconWrapper>
                    {collection.emoji ? (
                      <EmojiWrapper> {collection.emoji} </EmojiWrapper>
                    ) : (
                      <CollectionIcon />
                    )}
                  </CollectionIconWrapper>

                  <AddCollectionDialogCollectionName>
                    <span className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
                      {collection.name}
                    </span>
                  </AddCollectionDialogCollectionName>
                </AddCollectionDialogHeader>
                <Permission>
                  <RoleDropdown
                    additionalActions={
                      <>
                        <HexMenuDivider />
                        <HexMenuItem
                          css={`
                            text-align: center;
                          `}
                          intent={Intent.DANGER}
                          text="Remove collection"
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={() => onRemove(collection.id)}
                        />
                      </>
                    }
                    canShare={canShare}
                    explorerRoleCanViewChange={explorerRoleCanViewChange}
                    isComponent={isComponent}
                    isInCollectionsContext={true}
                    maxRole={maxGrantableRole ?? ProjectRole.EDITOR}
                    minRole={
                      isComponent ? ProjectRole.VIEWER : ProjectRole.APP_USER
                    }
                    selectedRole={collection.role}
                    small={true}
                    // eslint-disable-next-line react/jsx-no-bind
                    onSelectRole={(selectedRole: ProjectRole | null) =>
                      updateProjectRole(collection.id, selectedRole)
                    }
                  />
                </Permission>
              </CollectionRow>
            ))}
          </CollectionList>
        )}
      </Container>
    );
  });
