import { gql } from "@apollo/client";
import {
  Classes,
  Intent,
  PopoverPosition,
  PopoverProps,
  TagInputProps,
} from "@blueprintjs/core";
import { ItemListRenderer, ItemRenderer } from "@blueprintjs/select";
import { CollectionId, ProjectRole } from "@hex/common";
import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useDebounce } from "use-debounce";

import {
  HexButton,
  HexMenu,
  HexMenuItem,
  HexSpinner,
  HexSpinnerSizes,
  HexTooltip,
} from "../../../../hex-components";
import { HexMultiSelect2 } from "../../../../hex-components/HexMultiSelect";
import { CollectionGrantFragment } from "../../../../mutations/collections.generated";
import { useHexFlag } from "../../../../util/useHexFlags.js";
import { AddIcon, CollectionIcon } from "../../../icons/CustomIcons";
import { RoleDropdown } from "../../../share/RoleDropdown";
import { COLLECTIONS_PER_REQUEST } from "../collections-list/CollectionsListTab2";
import { CollectionSharingSummary } from "../shared/CollectionSharingSummary";

import { useCollectionsForAddFromProjectQuery } from "./AddCollectionsBar.generated";
import {
  AddCollectionDialogCollectionName,
  AddCollectionDialogHeader,
  CollectionIconWrapper,
  EmojiWrapper,
} from "./AddProjectToCollectionsDialog";

gql`
  query CollectionsForAddFromProject(
    $searchTerm: String
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
  ) {
    collections(
      searchTerm: $searchTerm
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          ...CollectionForHomeFragment
        }
      }
    }
  }
`;

const StyledHexMenuItem = styled(HexMenuItem)`
  padding: 3px 3px 3px 7px;

  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 15px;
`;

const StyledHexTooltip = styled(HexTooltip)`
  display: flex;
  width: 100%;
`;

const StyledAddIcon = styled(AddIcon)`
  align-self: center;
`;

const AddUserButtonDiv = styled.div`
  display: flex;
  margin-left: 5px;
`;

const CollectionPickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AddProjectInput = styled.div`
  position: relative;

  display: flex;

  width: 100%;
  min-width: 0;

  && .${Classes.TAG_INPUT_VALUES} {
    padding: 5px 110px 5px 5px;
  }
`;

const RoleDropdownWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
`;

const SEPARATOR_REGEX = /[,\n\r;]/;

export interface PotentialCollection {
  id: CollectionId;
  name: string;
  emoji: string | null;
  canManage: boolean;
  collectionGrants: readonly CollectionGrantFragment[];
}
export interface AddCollectionsBarProps {
  disabledIdsInSearch: Set<CollectionId>;
  excludedIdsFromSearch: Set<CollectionId>;
  addCollectionsCallback: (args: {
    collections: PotentialCollection[];
    role: ProjectRole | null;
  }) => void;
  canShare: boolean;
  maxRole: ProjectRole;
  setActiveTagsInSearch?: (hasActiveTags: boolean) => void;
  isComponent: boolean;
}

const POPOVER_PROPS: Partial<PopoverProps> = {
  fill: true,
  minimal: true,
  captureDismiss: true,
  matchTargetWidth: true,
  position: PopoverPosition.BOTTOM,
  rootBoundary: "viewport",
};

export const AddCollectionsBar: React.ComponentType<AddCollectionsBarProps> =
  React.memo(function AddCollectionsBar({
    addCollectionsCallback,
    canShare,
    disabledIdsInSearch,
    excludedIdsFromSearch,
    isComponent,
    maxRole,
    setActiveTagsInSearch,
  }: AddCollectionsBarProps) {
    const [searchString, setSearchString] = useState<string>("");
    const [debouncedSearchString] = useDebounce(searchString, 200, {
      maxWait: 600,
    });
    const explorerRoleCanViewChange = useHexFlag(
      "explorer-role-can-view-change",
    );

    const {
      data: searchData,
      error: searchError,
      loading: searchLoading,
    } = useCollectionsForAddFromProjectQuery({
      variables: {
        searchTerm: debouncedSearchString,
        first: COLLECTIONS_PER_REQUEST,
        last: null,
        before: null,
        after: null,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

    // if the user cannot share the project, set default role to null.
    const [potentialRole, setPotentialRole] = useState<ProjectRole | null>(
      !canShare ? null : ProjectRole.VIEWER,
    );
    const [potentialCollections, _setPotentialCollections] = useState<
      PotentialCollection[]
    >([]);
    const setPotentialCollections = useCallback(
      (collections: PotentialCollection[]) => {
        _setPotentialCollections(collections);
        setActiveTagsInSearch?.(collections.length > 0);
      },
      [setActiveTagsInSearch],
    );

    const allPotentialAndExcludedIds = useMemo(() => {
      const newSet = new Set([
        ...excludedIdsFromSearch,
        ...potentialCollections.map(({ id }) => id),
      ]);
      return newSet;
    }, [excludedIdsFromSearch, potentialCollections]);

    const [activeItem, setActiveItem] = useState<PotentialCollection | null>(
      null,
    );

    const queriesToShowFromSearchbar: PotentialCollection[] = useMemo(() => {
      return (
        searchData?.collections.edges
          .map(
            ({ node: { canManage, collectionGrants, emoji, id, name } }) => ({
              id,
              name,
              emoji,
              canManage,
              collectionGrants,
            }),
          )
          .filter((c) => !allPotentialAndExcludedIds.has(c.id)) ?? []
      );
    }, [searchData?.collections.edges, allPotentialAndExcludedIds]);

    const onRemove = useCallback(
      (item: PotentialCollection, _index?: number) => {
        setPotentialCollections(
          potentialCollections.filter((i) => i.id !== item.id),
        );
      },
      [potentialCollections, setPotentialCollections],
    );

    const movePotentialCollectionsToSelectedList = useCallback(() => {
      if (potentialCollections.length > 0) {
        addCollectionsCallback({
          collections: potentialCollections,
          role: potentialRole,
        });
        setPotentialCollections([]);
      }
    }, [
      addCollectionsCallback,
      potentialCollections,
      potentialRole,
      setPotentialCollections,
    ]);

    const itemRenderer: ItemRenderer<PotentialCollection> = useCallback(
      (item, itemProps) => {
        if (!itemProps.modifiers.matchesPredicate) {
          return null;
        }

        const emojiAndText = (
          <AddCollectionDialogHeader>
            <CollectionIconWrapper>
              {item.emoji ? (
                <EmojiWrapper> {item.emoji} </EmojiWrapper>
              ) : (
                <CollectionIcon />
              )}
            </CollectionIconWrapper>

            <AddCollectionDialogCollectionName>
              <span className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
                {item.name}
              </span>
            </AddCollectionDialogCollectionName>
          </AddCollectionDialogHeader>
        );

        const collectionAlreadyAdded = disabledIdsInSearch.has(item.id);
        const rowClickDisabled = !item.canManage || collectionAlreadyAdded;
        const rowClickDisabledMessage = collectionAlreadyAdded
          ? "Project already in collection."
          : "Must be collection manager to add project.";

        return (
          <StyledHexTooltip
            key={`tooltip-${item.id}`}
            content={rowClickDisabledMessage}
            disabled={!rowClickDisabled}
          >
            <StyledHexMenuItem
              key={`collection-${item.id}`}
              active={itemProps.modifiers.active}
              disabled={rowClickDisabled}
              labelElement={
                <CollectionSharingSummary
                  collectionGrants={item.collectionGrants}
                  disableTooltip={rowClickDisabled}
                />
              }
              text={emojiAndText}
              onClick={itemProps.handleClick}
            />
          </StyledHexTooltip>
        );
      },
      [disabledIdsInSearch],
    );

    const itemListRenderer: ItemListRenderer<PotentialCollection> = useCallback(
      ({ items, itemsParentRef, renderItem }) => {
        return (
          <HexMenu ulRef={itemsParentRef}>
            {searchLoading ? (
              <StyledHexMenuItem
                disabled={true}
                text={<HexSpinner size={HexSpinnerSizes.STANDARD} />}
              />
            ) : searchError != null ? (
              <StyledHexMenuItem disabled={true} text="Something went wrong" />
            ) : items.length > 0 ? (
              <>{items.map((i, idx) => renderItem(i, idx))}</>
            ) : (
              <StyledHexMenuItem disabled={true} text="No results" />
            )}
          </HexMenu>
        );
      },
      [searchError, searchLoading],
    );

    const itemsEqual = useCallback(
      (a: PotentialCollection, b: PotentialCollection) => a.id === b.id,
      [],
    );

    const tagRenderer = useCallback(
      (item: PotentialCollection) => (
        <span data-type-tag="collection">{item.name}</span>
      ),
      [],
    );

    const onItemSelect = useCallback(
      (item: PotentialCollection, evt?: SyntheticEvent<HTMLElement, Event>) => {
        evt?.stopPropagation();
        setPotentialCollections(potentialCollections.concat(item));
        setSearchString("");
        setActiveItem(null);
      },
      [potentialCollections, setPotentialCollections],
    );

    const tagInputProps: Partial<TagInputProps> = useMemo(
      () => ({
        className: Classes.FILL,
        leftIcon: <StyledAddIcon />,
        placeholder: "Add collections...",
        separator: SEPARATOR_REGEX,
        tagProps: { minimal: true, intent: Intent.NONE },
      }),
      [],
    );

    return (
      <CollectionPickerWrapper>
        <AddProjectInput>
          <HexMultiSelect2<PotentialCollection>
            activeItem={activeItem}
            fill={true}
            itemListRenderer={itemListRenderer}
            itemRenderer={itemRenderer}
            items={queriesToShowFromSearchbar}
            itemsEqual={itemsEqual}
            popoverProps={POPOVER_PROPS}
            query={searchString}
            resetOnQuery={true}
            selectedItems={potentialCollections}
            tagInputProps={tagInputProps}
            tagRenderer={tagRenderer}
            onActiveItemChange={setActiveItem}
            onItemSelect={onItemSelect}
            onQueryChange={setSearchString}
            onRemove={onRemove}
          />
          <RoleDropdownWrapper>
            <RoleDropdown
              canShare={canShare}
              explorerRoleCanViewChange={explorerRoleCanViewChange}
              isComponent={isComponent}
              isInCollectionsContext={true}
              maxRole={maxRole}
              minRole={isComponent ? ProjectRole.VIEWER : ProjectRole.APP_USER}
              selectedRole={potentialRole}
              small={true}
              onSelectRole={setPotentialRole}
            />
          </RoleDropdownWrapper>
        </AddProjectInput>
        <AddUserButtonDiv>
          <HexButton
            intent="success"
            text="Add"
            onClick={movePotentialCollectionsToSelectedList}
          />
        </AddUserButtonDiv>
      </CollectionPickerWrapper>
    );
  });
